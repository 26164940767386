.dragatron-service {
  display: flex;
  flex-direction: column;
  background-color: #3d5a80;
  color: white;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 10px;
}

.service-content {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.service-header {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.service-id {
  display: none;
}

.service-title {
  font-size: 20px;
  margin-top: 0;
  text-transform: uppercase;
  font-weight: bold;
}

.service-details-container {
  display: flex;
  flex-direction: column;
}

.service-details {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

.service-details li {
  position: relative;
  padding-left: 10px;
  margin-bottom: 2px;
  font-size: 14px;
}

.service-details li::before {
  content: "\2022";
  color: white;
  font-size: 1em;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.service-image {
  display: none;
}

.form-label {
  font-size: 1rem; /* Adjust the font size for better readability */
  white-space: normal; /* Ensure text wraps properly */
}

.input-field {
  padding: 10px;
  margin-bottom: 10px;
  width: 100%;
}
@media (min-width: 768px) {
  .dragatron-service {
    flex-direction: row;
  }

  .service-content {
    padding: 20px 20px 20px 0;
  }

  .service-id {
    display: block;
    font-size: 70px;
    font-weight: bold;
    margin-left: 20px;
    margin-right: 20px;
    line-height: 1;
    align-self: center;
  }

  .service-title {
    font-size: 25px;
  }

  .service-details-container {
    flex-direction: row;
    gap: 20px;
  }

  .service-details {
    flex: 1;
  }

  .service-image {
    display: flex;
    width: 120px;
    height: 120px;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    align-self: center;
  }

  .service-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

@media (max-width: 600px) {
  .improvements-input .MuiFormLabel-root {
    font-size: 12px !important;
    line-height: 1.2 !important;
    white-space: normal !important;
  }
  .improvements-input .MuiInputBase-input {
    font-size: 14px !important;
  }
}
